<template>
  <div class="portal_info">
    <div class="layout">
      <div class="acit_main">
        <div class="header">
          <!-- 标题 -->
          <div class="title">
            <p>{{info.title}}</p>
          </div>
          <!-- 描述 -->
          <div class="description">
            <p>{{info.description}}</p>
          </div>
          <!-- 各种文章信息展示 -->
          <div class="title_msg">
            <div class="msg_time">发布日期：{{info.create_time}}</div>
            <div class="msg_fun">
              <!-- 阅读数量 -->
              <div class="reads">
                <i class="fa fa-eye"></i>
                <span>({{info.reads}})</span>
              </div>
              <!-- 点赞、点赞数量 -->
              <div class="praise">
                <i class="fa fa-thumbs-o-up" v-if="info.dolike == 0" @click="praise"></i>
                <i class="fa fa-thumbs-up" v-else @click="praise"></i>
                <span>({{info.like}})</span>
              </div>
            </div>
          </div>
          <!-- 关键词 -->
          <div class="keywords" v-if="keywords[0] != ''">
            <p>
              关键字：
              <span v-for="(item,key) in keywords" :key="key">
                <span class="keywords_item" @click="goSearch(item)">{{item}}</span>
                <el-divider direction="vertical" v-if="key!=0&&keywords.length!=key"></el-divider>
              </span>
            </p>
          </div>
        </div>
        <el-divider></el-divider>
        <!-- 文子内容渲染 -->
        <div class="content" v-html="info.content" v-if="info.type == 1"></div>
        <!-- 视频 -->
        <div class="video" v-if="info.type == 2">
          <video :src="info.videourl" controls></video>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getuserartclass, getuserart, likeart } from "@/api/article.js";
export default {
  data() {
    return {
      queryData: [],
      info: [],
      keywords: []
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 页面初始加载
    init() {
      // 文章数据加载
      // 1-获取get数据
      let queryData = this.$route.query;
      this.queryData = queryData;
      let data = {
        id: this.queryData.id
        // class: this.queryData.classId
      };
      getuserart(data).then(res => {
        if (res.code === 400200) {
          this.info = res.data[0];
          this.keywords = this.info.keywords.split(",");
        }
      });
    },
    // 点赞
    praise() {
      likeart({ aid: this.info.id }).then(res => {
        if (this.info.dolike == 0) {
          this.info.dolike = 1;
        } else {
          this.info.dolike = 0;
        }
      });
    },
    // 跳转搜索页面
    goSearch(str) {
      this.$route.meta.ifDoFresh = true;
      this.$router.push(`/answer_home?keywords=${str}`);
    }
  }
};
</script>
<style lang="less">
.portal_info {
  .layout {
    width: 100%;
    min-width: 320px;
    max-width: 812px;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
    .acit_main {
      position: relative;
      .header {
        .title {
          font-size: 18px;
          font-weight: 700;
        }
        .description {
          margin-top: 5px;
          font-size: 14px;
          text-indent: 2em;
        }
        .title_msg {
          padding-top: 3px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .msg_time {
            flex: 5;
            font-size: 12px;
            color: rgb(58, 57, 57);
          }
          .msg_fun {
            flex: 3;
            display: flex;
            justify-content: flex-end;
            .praise {
              text-align: center;
              display: block;
              width: 50px;
              height: 100%;
              .fa-thumbs-o-up,
              .fa-thumbs-up {
                font-size: 18px;
                color: rgb(247, 67, 67);
              }
              .fa-thumbs-up {
                // transition: all 0.5s;
                // transform: scale(1.5);
              }
            }
          }
        }
        .keywords {
          padding-top: 2px;
          font-size: 12px;
          color: rgb(58, 57, 57);
          .keywords_item {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .content {
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
      .video {
        video {
          width: 100%;
        }
      }
    }
    .el-divider--horizontal {
      margin: 8px 0;
    }
  }
}
</style>